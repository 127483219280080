import { Menu, MenuItem ,Card,Row,Col,Input,Button, Upload, Progress, Icon, Table, TableColumn,Message, Form, FormItem, ButtonGroup, Loading, Carousel, CarouselItem, RadioButton, RadioGroup, Slider, Dialog, Popconfirm, Divider, Select, Option, Checkbox} from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// import 'default-passive-events'

locale.use(lang)


Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Icon)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Loading)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Card)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Slider)
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(Dialog)
Vue.use(Card)
Vue.use(Popconfirm)
Vue.use(Divider)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
// Vue.component(Message.name,Message)

Vue.config.productionTip = false
Vue.prototype.$message = Message

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
