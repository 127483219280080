<template>
  <el-row id="home">
    <el-col :span="20" :push="2" id="help-content">
      <el-row id="first-section" type="flex" align="middle">
        <el-col :span="11" :push="1">
          <div id="first-img-introduce">
            <div class="introduce-section">
              <h2 class="section-header">
                1. DeepOMe is a precise tool for prediction of
                2′-O-methylations.
              </h2>
              <p>
                
                <home-icon></home-icon> 2′-O-methylations
                (2′-O-Me) are one of the most important layers of regulatory
                control over gene expression.
              </p>
              <p>
                <home-icon></home-icon> By utilizing Deep
                Learning, DeepOMe has developed a new algorithm for prediction
                of 2′-O-Me sites.
              </p>
              <p>
                <home-icon></home-icon> DeepOMe can predict the
                exact locations of 2′-O-Me by a many-to-many mode.
              </p>
            </div>
          </div>
          <el-col id="start" :span="6">
            <el-button id="start-button" @click="toServer"
              >Get Started!</el-button
            >
          </el-col>
        </el-col>
        <el-col :span="10" :push="2" id="first-img">
          <div class="img">
            <img src="..\..\public\home\home_1.png" alt="" />
          </div>
        </el-col>
      </el-row>
      <el-row> </el-row>
      <el-divider></el-divider>
      <el-row id="second-section" type="flex" align="middle">
        <el-col :push="2" :span="8">
          <div class="img">
            <img src="..\..\public\home\home_2.jpg" alt="" />
          </div>
        </el-col>
        <el-col :span="10" :push="4">
          <div class="introduce-section">
            <h2 class="section-header">
              2. DeepOMe updates the way of modeling 2′-O-Me sites prediction.
            </h2>
            <p>
              <home-icon></home-icon> A many-to-many procedure is
              introduced to train and test.
            </p>
            <p>
              <home-icon></home-icon> A hybrid CNN and BLSTM
              architecture is utilized to automatedly extract features from
              primary mRNA sequences.
            </p>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row id="third-section" type="flex" align="middle">
        <el-col :span="11" :push="1">
          <div class="introduce-section">
            <h2 class="section-header">3. Robust deep learning model</h2>
            <p>
              <home-icon></home-icon> DeepOMe is constructed by a
              network with ten dilated 1D convolutional neural networks and two
              bidirectional LSTM layers.
            </p>
            <p>
              <home-icon></home-icon> Softmax function is
              implemented in the output fully connected layer.
            </p>
          </div>
        </el-col>
        <el-col :span="9" :push="2">
          <div class="img">
            <img src="..\..\public\home\home_3.jpg" alt="" />
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import Icon from "./Icon"
export default {
  name: "home",
  components:{
    "home-icon":Icon
  },
  methods: {
    toServer() {
      this.$router.push("server");
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  text-align: left;
  font-size: 20px !important;
  font-family: Roboto, sans-serif;
}
#first-section {
  align-items: center;
}
#first-section,
#second-section {
  margin-top: 30px;
}
.section-header {
  font-size: 28px;
  margin-bottom: 0.5em;
  // line-height: 1.5;
}
#first-img-introduce {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5% 0;
}
.introduce-section {
  // margin: 70px 0 50px 15px;
  p {
    // line-height: 40px;
    margin-top: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.65);
  }
}
.el-icon-collection-tag {
  font-size: 25px;
  color: rgb(21, 81, 141);
  transform: rotate(-45deg);
}
img {
  width: 100%;
}
#start {
  // margin-top: 30px;
  text-align: center;
  #start-button {
    background-color: rgb(21, 81, 141);
    padding: 15px 60px;
    color: #fff;
    font-size: 18px;
  }
}
#third-section {
  margin-bottom: 30px;
}
</style>
