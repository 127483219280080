<template>
  <div id="jobs">
    <div class="title">
      <h2><i class="el-icon-files"></i>Task Records</h2>
    </div>
    <el-table
      :data="jobs"
      cell-class-name="list-cell"
      header-cell-class-name="list-header"
      header-row-class-name="row-header"
    >
      <el-table-column label="Job ID" prop="id"></el-table-column>
      <el-table-column label="Submit Time" prop="uploadTime"></el-table-column>
      <el-table-column label="FDR" prop="fdr">
        <template slot-scope="scope">
          {{ scope.row.fdr | fdrfilter }}
        </template>
      </el-table-column>
      <el-table-column label="State" width="120px">
        <template slot-scope="scope">
          <div
            style="height: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
          >
            &nbsp;
          </div>
          <!-- {{scope.row.state}} -->
          {{ scope.row.state }}
        </template>
      </el-table-column>
      <el-table-column label="Result">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            class="view-button"
            :loading="scope.row.state == 'Running' || scope.row.state == 'Uploading'"
            @click="pushRoute(scope.row.id)"
            :disabled="scope.row.state == 'Failed'"
            >View</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Delete" width="135px">
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="comfirm"
            cancel-button-text="cancel"
            title="Sure to delete?"
            placement="right"
            popper-class="confirm-box"
            :visible-arrow="true"
            class="delete-button"
            @confirm="deleteJob(scope.row.id)"
          >
            <el-button
              type="danger"
              size="mini"
              slot="reference"
              :disabled="scope.row.state == 'Uploading'"
              >Delete</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import axios from 'axios';
export default {
  name: "jobs-table",
  props: {
    jobs: Array,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    pushRoute(id) {
      let routeData = this.$router.resolve({ path: `/result/${id}` });
      window.open(routeData.href, "_blank");
    },
    deleteJob(id) {
      this.$emit("deleteJob", id);
      axios.post("api/delete",{id:id}).catch((error)=>{
        console.log(error);
      })
    },
  },
  filters: {
    fdrfilter(val) {
      switch (val) {
        case 0.15:
          return "0.15(Medium cutoff)";
        case 0.1:
          return "0.1(High cutoff)";
        case 0.2:
          return "0.2(Low cutoff)";
        default:
          return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  margin: 5px 0 25px 0;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(21, 81, 141);
  h2 {
    font-size: 24px;
  }
  i {
    margin-right: 5px;
  }
}
/deep/ .view-button {
  // height: 35px;
  padding:10px 50px;
  font-size: 14px;
}
/deep/ .delete-button .el-button {
  font-size: 14px;
  padding: 10px 30px;
  // color:#FF0000
}

/deep/ .list-header {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0,0,0,.85);
  text-align: center;
  background-color: #dadada;
}
/deep/ .row-header {
}
/deep/ .list-cell {
  text-align: center;
  font-size: 16px;
}


</style>
