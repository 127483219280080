<template>
  <div id="server">
    <el-row id="input" type="flex">
      <el-col :span="18" :push="3" type="flex">
        <div class="title">
          <h2><i class="el-icon-document"></i>Sequence input</h2>
        </div>
        <el-row type="flex">
          <el-col :span="12" class="data-input">
            <el-input
              v-model="form.sequence"
              type="textarea"
              id="input-box"
              :placeholder="placeholder"
              @change="clearFileList"
            ></el-input>
            <div id="button-area">
              <el-button
                class="click-button"
                id="example-button"
                @mouseup.native="buttonBlur"
                @click="showExample"
                >Example</el-button
              >
              <el-button
                class="click-button"
                id="clear-button"
                type="danger"
                @click="clearInput"
                >Clear</el-button
              >
            </div>
          </el-col>
          <el-col :span="11" :push="1" id="upload">
            <div>
              <p id="uploadTitle">
                Or select local files to upload.(Upload file size &lt; 25M)
              </p>
              <el-progress
                ref="process"
                :color="process.status"
                :text-inside="true"
                :stroke-width="20"
                :percentage="process.percentage"
                id="file-process"
                :format="progressContent"
              ></el-progress>
              <el-upload
                action=""
                :auto-upload="false"
                ref="upload"
                :on-change="listChange"
                :on-exceed="fileOver"
                :before-upload="InitProcess"
                :http-request="uploadFile"
              >
                <el-button
                  class="click-button"
                  icon="el-icon-upload2"
                  :disabled="uploadDisable"
                  id="upload-button"
                  >Upload File</el-button
                >
              </el-upload>
              <div id="cutoff">
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <h2>Cutoff:</h2>
                  </el-col>
                  <el-col :span="12">
                    <div id="threshold-group">
                      <el-radio-group v-model="fdr">
                        <el-radio-button
                          v-for="(value, key) in fdrTable"
                          :label="value"
                          :key="key"
                          >{{ key }}</el-radio-button
                        >
                      </el-radio-group>
                    </div>
                  </el-col>
                </el-row>
                <fdr-bar :fdr.sync="fdr"></fdr-bar>
              </div>
            </div>
            <div id="submit">
              <el-button
                id="submit-button"
                type="primary"
                @mouseup.native="buttonBlur"
                :loading="load"
                @click="upload"
                >Submit</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row id="job-list">
      <el-col :span="18" :push="3">
        <jobs-list :jobs="jobs" @deleteJob="deleteJob"></jobs-list>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
// import IbsCharts from "../assets/js/IbsCharts.js";
import scoreTable from "./Server/ScoreTable";
import { thresoldTable } from "../assets/js/ThresoldTable";
import { exampleSeq } from "../assets/js/ExampleSeq";
import IbsUtils from "../assets/js/IbsUtils.js";
import JobsList from "./Server/JobsList.vue";
import FDRbar from "./Server/FDRbar";

export default {
  name: "server",
  components: {
    "score-table": scoreTable,
    "jobs-list": JobsList,
    "fdr-bar": FDRbar,
  },
  data() {
    return {
      files: [],
      uploadDisable: false,
      // uploadUrl: "/test", //localhost:5000/api/submit
      uploadUrl: "/api/submit",
      resultUrl: "/api/result",
      load: false,
      placeholder: "input nucleotide sequence in fasta format",
      form: {
        sequence: "",
        cutoff: "",
      },
      result: [],
      resultTable: {},
      // fileId: "", // 任务id
      interval: {}, //定时器
      fdrTable: {
        High: 0.1,
        Medium: 0.15,
        Low: 0.2,
      },
      jobs: [], // 任务列表
      fdr: 0.15,
      process: {
        status: "#409eff",
        percentage: 0,
      }, //上传进度
      currentId: "",
      currentDate: {},
      jobStatus: {}
    };
  },

  methods: {
    clicktest() {
      this.test = this.test + "1";
    },
    buttonBlur(e) {
      e.currentTarget.blur();
    },
    listChange(file, flist) {
      if (flist.length > 1) {
        console.log("remove");
        this.$refs.upload.handleRemove(flist[0]);
      }
      let name = file.name;
      let format = name.split(".").slice(-1)[0];
      if (format !== "fasta") {
        this.$refs.upload.abort();
        this.$message.error({
          message:
            "<span style='font-size:20px'>Invalid file format! Please upload a <span style='font-weight:700'>fasta</span> file.</span>",
          duration: 10000,
          showClose: true,
          dangerouslyUseHTMLString: true,
        });
        this.$refs.upload.clearFiles();
        this.load = false;
        this.process.status = "#F00";
        return false;
      }
      this.clearInput();
      // this.process.percentage = 0;
      this.$refs.upload.submit();
    },
    fileOver(files, fileList) {
      this.$message.error({
        message:
          "<span style='font-size:20px;line-height:25px'>Can only upload one file at a time. You can merge multiple files into one.</span>",
        duration: 10000,
        showClose: true,
        dangerouslyUseHTMLString: true,
      });
    },
    clearInput() {
      this.form.sequence = "";
    },
    clearFileList() {
      this.$refs.upload.clearFiles();
      this.process.status = "#409eff";
      this.process.percentage = 0;
    },
    uploadChange(state) {
      console.log(state);
    },
    showExample() {
      this.form.sequence = exampleSeq;
      this.clearFileList();
    },
    getFilename(date) {
      let year = date.getFullYear();
      let month =
        date.getMonth().toString().length == 1
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      return `${year}-${month}-${day} ${hour}-${minute}-${second}`;
    },
    checkSeq(sequence) {
      let seq = sequence.split("\n");
      //验证序列是否为fasta格式
      if (seq[0][0] !== ">") {
        this.$message.error({
          message:
            "<span style='font-size:20px'>Invalid input sequence! Please input a <span style='font-weight:700'>fasta</span> sequence.</span>",
          duration: 10000,
          showClose: true,
          dangerouslyUseHTMLString: true,
        });
        this.load = false;
        return false;
      }
      //验证序列是否有其他字符
      for (let i = 0; i < seq.length; i++) {
        if (seq[i][0] === ">") {
          continue;
        }
        let index = seq[i].search(/[^atcgnATCGN]/);
        let site = seq[i][index];
        if (index !== -1) {
          this.$message.error({
            message: `<span style='font-size:20px;line-height:25px'>Invalid input sequence! The sequence should only contain ACTGN. Find "${site}" in seq ${
              (i + 1) / 2
            } index ${index + 1}</span>`,
            duration: 15000,
            showClose: true,
            dangerouslyUseHTMLString: true,
          });
          this.load = false;
          return false;
        }
      }
      return sequence;
    },
    getindex(id) {
      for (let i in this.jobs) {
        if (id == this.jobs[i].id) {
          return i;
        }
      }
    },
    //进度条内容
    progressContent(percentage) {
      if (this.process.status == "#F00") {
        this.process.percentage = 100;
        return "Failed";
      } else {
        return percentage + "";
      }
    },
    InitProcess() {
      this.process.status = "#409eff";
      this.process.percentage = 0;
    },
    async uploadFile() {
      // console.log(this.currentId);
      console.log("upload file");
      let form = new FormData();
      form.append("file", this.$refs.upload.uploadFiles[0].raw);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          this.showProcess = true;
          let process =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.process.percentage = process;
        },
      };
      let date = new Date();
      let id = IbsUtils.getRandomId(date.getTime());
      this.currentId = id;
      this.currentDate = date;
      form.append("id", id);
      let result = await axios
        .post("/api/accept", form, config)
        .then(() => {
          // this.$refs.upload.clearFiles();
        })
        .catch((error) => {
          this.process.status = "#F00";
        });
    },
    // 上传函数
    async upload() {
      let form = new FormData();
      // 输入序列验证
      let seq;
      if (this.$refs.upload.uploadFiles.length) {
        for (let i in this.jobs) {
          if (this.jobs[i].id == this.currentId) {
            this.$message.info({
              message:
                "<span style='font-size:20px;line-height:25px'>There is a same job is running.</span>",
              duration: 10000,
              showClose: true,
              dangerouslyUseHTMLString: true,
            });
            return false;
          }
        }
      } else {
        seq = this.form.sequence;
        let checkResult = this.checkSeq(seq);
        let date = new Date();
        this.currentId = IbsUtils.getRandomId(date.getTime());
        this.currentDate = date;
        if (checkResult) {
          form.append("sequence", checkResult);
        } else {
          return false;
        }
      }
      this.load = true;
      form.append("cutoff", thresoldTable[this.fdr]);
      // console.log(thresoldTable[this.fdr]);
      // 生成id
      // let date = new Date();
      // let id = IbsUtils.getRandomId(date.getTime());
      let id = this.currentId;
      form.append("id", id);
      form.append("fdr", this.fdr);

      let formatTime = this.getFilename(this.currentDate).split(" ");
      formatTime[1] = formatTime[1].replace(/-/g, ":");
      let uploadTime = formatTime.join(" ");
      form.append("time", uploadTime);

      // 该次任务信息
      let job = {
        id,
        uploadTime,
        fdr: this.fdr,
        state: "Uploading", //Running, Compelete, Error
        loading: true,
      };
      this.jobs.push(job);
      this.jobStatus[id] = 0;
      // let id = IbsUtils.getRandomId(this.getFilename(date));
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress:(progressEvent) => {
        //   this.showProcess = true;
        //   let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
        //   this.process.percentage = process
        // }
      };
      // 保存任务信息
      this.saveJobInformation();
      let index = this.getindex(id);
      axios
        .post(this.uploadUrl, form, config)
        .then((res) => {
          this.load = false;
          // console.log(response);
          // 启动向后台请求结果的定时器
          this.jobs[index].state = "Running";
          this.saveJobInformation();
          let interval = setInterval(() => {
            this.getResult(id, interval);
            this.interval[id] = interval;
          }, 3000);
          this.clearFileList();
        })
        .catch((error) => {
          console.log(error);
          this.$message.error({
            message:
              "<span style='font-size:20px;line-height:25px'>Fail to upload sequence, please refresh the page and try again. If it happens frequently, please contact us.</span>",
            duration: 15000,
            showClose: true,
            dangerouslyUseHTMLString: true,
          });
          this.load = false;
          // this.process = 0
          // this.process.status = "#F00";
          // this.jobs[index].state = "Failed";
          this.saveJobInformation();
        });
    },
    // 向后台发送请求获取结果
    async getResult(fileId, interval) {
      let response = await axios
        .post(this.resultUrl, { id: fileId })
        .catch((error) => {
          this.jobStatus[fileId] += 1;
          // 失败超过 10 次才停止
          if (this.jobStatus[fileId] > 10) {
            console.log("request result error.");
            console.log(error);
            this.$message.error({
              message:
                "<span style='font-size:20px;line-height:25px'>An error has occurred, please refresh the page and try again. If it happens frequently, please contact us.</span>",
              duration: 15000,
              showClose: true,
              dangerouslyUseHTMLString: true,
            });
            this.load = false;
            let index = this.getindex(fileId);
            this.jobs[index].state = "Failed";
            clearInterval(interval);
          }
        });
      // console.log(response);
      //如果处理完成了，response.date.result是结果，否则是false
      if (response?.data?.result) {
        clearInterval(interval);
        // console.log(response);
        // let result = [];
        // let data = response.data.result;
        // for (let i in data.name) {
        //   let seqObj = {
        //     name: data.name[i],
        //     sites: [],
        //     fold: "",
        //     seq: "",
        //     domain: "",
        //   };
        //   for (let j in data.index[i]) {
        //     let start = Math.max(data.index[i][j] - 7, 0);
        //     let end = Math.min(data.index[i][j] + 8, data.seq[i].length);
        //     let seq = data.seq[i].slice(start, end);
        //     if (start === 0) {
        //       seq = "5'-" + seq;
        //     }
        //     if (end === data.seq[i].length) {
        //       seq = seq + "-3'";
        //     }
        //     let siteObj = {
        //       position: data.index[i][j] + 1,
        //       sequence: seq,
        //       score: parseFloat(data.score[i][j]),
        //     };
        //     seqObj.sites.push(siteObj);
        //   }
        //   seqObj.fold = data.svg[i];
        //   seqObj.seq = data.seq[i];
        //   seqObj.domain = data.domain[data.name[i].split(" ")[0]];
        //   result.push(seqObj);
        // }
        let index = this.getindex(fileId);
        if (index) {
          console.log(index);
          this.jobs[index].state = "Complete";
        }
        this.load = false;
        this.saveJobInformation();
      }
    },
    saveJobInformation() {
      let joblist = [];
      for (let i in this.jobs) {
        let j = JSON.stringify(this.jobs[i]);
        joblist.push(j);
      }
      let value = "[" + joblist.join() + "]";
      window.localStorage.setItem("jobs", value);
      window.localStorage.setItem(
        "resultTable",
        JSON.stringify(this.resultTable)
      );
    },
    deleteJob(id) {
      let index = this.getindex(id);
      this.jobs.splice(index, 1);
      clearInterval(this.interval[id]);
      this.saveJobInformation();
    },
  },
  mounted() {
    this.jobs = window.localStorage.getItem("jobs")
      ? eval(window.localStorage.getItem("jobs"))
      : [];
    this.resultTable = window.localStorage.getItem("resultTable")
      ? JSON.parse(window.localStorage.getItem("resultTable"))
      : {};
    // 如果有Running则继续请求
    for (let i in this.jobs) {
      if (this.jobs[i].state === "Running") {
        let interval = setInterval(() => {
          this.getResult(this.jobs[i].id, interval);
          this.interval[this.jobs[i].id] = interval;
        }, 3000);
      }
      if (this.jobs[i].state == "Uploading") {
        this.jobs[i].state = "Failed";
      }
    }
    this.saveJobInformation();
  },
  beforeDestroy() {
    // for (let i in this.jobs) {
    //   console.log(this.jobs);
    //   if ((this.jobs[i].state = "Uploading")) {
    //     this.jobs[i].state = "Failed";
    //   }
    // }
    // this.saveJobInformation();
  },
};
</script>

<style lang="scss" scoped>
#server {
  // height: 100%;
  margin: 0 auto;
}
.click-button {
  padding: 10px 20px;
  font-size: 14px;
}
.title {
  text-align: left;
  h2 {
    font-size: 24px;
  }
  i {
    margin-right: 5px;
  }
}
#button-area {
  margin-top: 5px;
  #example-button {
    float: left;
    margin: 5px 0 0 0;
  }
  #clear-button {
    // width: 70px;
    // height: 35px;
    float: right;
    margin: 5px 0 0 5px;
  }
}

#upload {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
// 文本域
/deep/ #input-box {
  padding: 1px 0 0 1px;
  font-size: 14px;
  min-height: 250px !important;
}
#upload-button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #ffffff;
  background-color: rgb(21, 81, 141);
  width: 180px;
  height: 42px;
  padding: 0;
}
.title {
  margin: 5px 0 25px 0;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(21, 81, 141);
}
#uploadTitle {
  font-size: 14px;
  margin-bottom: 15px;
}
#cutoff {
  margin-top: 15px;
  position: relative;
  line-height: 46px;
  h2 {
    font-size: 16px;
    display: inline-block;
    margin-right: 5px;
    font-weight: 700;
  }
  .el-input {
    font-size: 18px;
    display: inline-block;
    width: 184px;
    /deep/ #cutoff-input {
      display: block;
      padding: 1px 0 0 5px;
    }
  }
  #thresold-group {
    display: inline-block;
  }
}

#submit {
  text-align: right;
  #submit-button {
    padding: 10px 30px;
    font-size: 14px;
    // height: 35px;
    margin: 5px 0 0 5px;
  }
}
#result {
  text-align: left;
  margin-top: 25px;
}
// #download {
//   margin-bottom: 15px;
// }
// /deep/ #scoreTable {
//   font-size: 18px;
//   color: #000;
//   thead th {
//     font-weight: 500;
//     color: #000;
//     background-color: #e8e8e8;
//   }
//   // .seq-result {
//   //   text-align: center;
//   //   font-size: 18px;
//   // }
//   .el-table__expanded-cell {
//     padding: 5px 20px;
//   }
// }
#job-list {
  margin-top: 40px;
}
.el-message__content {
  font-size: 20px;
}
#file-process {
  margin-bottom: 10px;
  /deep/.el-progress-bar__innerText {
    font-size: 14px;
  }
}
</style>
