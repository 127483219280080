<template>
  <div class="score-table">
    <el-table :data="data" border class="seq-result">
      <el-table-column
        label="Position"
        prop="position"
        align="center"
        sortable
      ></el-table-column>
      <el-table-column align="center">
        <template slot="header"> Sequence </template>
        <template slot-scope="seq">
          <span
            >{{ seq.row.sequence | frontSeq
            }}<span style="color: #f00; font-weight: 700">{{
              seq.row.sequence | site(seq.row.position)
            }}</span
            >{{ seq.row.sequence | backSeq }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Score"
        prop="score"
        align="center"
        sortable
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import IbsCharts from "../../assets/js/IbsCharts.js";
import axios from "axios";

export default {
  name: "score-table",
  data() {
    return {
      ibs: null,
      load: false,
    };
  },
  props: {
    data: {
      type: Array,
    },
    fileId: {
      type: String,
    },
    svg: {
      type: String,
    },
  },
  filters: {
    frontSeq(seq) {
      if (isNaN(parseInt(seq[0]))) {
        return seq.slice(0, 7);
      } else {
        return seq.slice(0, -8);
      }
    },
    backSeq(seq) {
      if (isNaN(parseInt(seq.slice(-2, -1)))) {
        return seq.slice(-7);
      } else {
        return seq.slice(8);
      }
    },
    site(seq, position) {
      if (isNaN(parseInt(seq[0]))) {
        return seq.slice(7, 8);
      } else {
        return seq.slice(position + 2, position + 3);
      }
    },
  },
  methods: {
    async visualize() {
      this.load = true;
      let form = new FormData();
      form.append("id", this.fileId);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let response = await axios
        .post("localhost:8000/api/visualize", form, config)
        .catch((error) => {
          console.log(error);
          this.$message.error({
            message:
              "<span style='font-size:20px;line-height:25px'>An error has occurred, please refresh the page and try again. If it happens frequently, please contact us.</span>",
            duration: 15000,
            showClose: true,
            dangerouslyUseHTMLString: true,
          });
        });
      this.load = false;
      if (response) {
        let json = response.data.result;
        console.log(json);
        let canvas = this.$refs.canvas;
        this.ibs = IbsCharts.init(canvas, false);
      }
    },
    showPic() {
      
    }
  },
  updated() {
    // let con = new DOMParser();
    // if (this.svg) {
      
    // }
  },
};
</script>

<style lang="scss" scoped>
.seq-result {
  text-align: center;
  font-size: 18px;
}

.visualize {
  margin-top: 15px;
  h4 {
    font-size: 22px;
    // text-indent: 2em;
    font-weight: 400;
    line-height: 20px;
    vertical-align: center;
  }
  i {
    display: inline-block;
    font-size: 20px;
    margin-right: 7px;
  }
  span {
    display: inline-block;
    vertical-align: center;
  }
  .canvas {
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border: solid 1px #15518d;
  }
}
</style>
