<template>
  <div>
    <el-row id="rna-visualize">
      <el-col :span="22">
        <el-col :push="1">
          <h3 class="sub-title">mRNA Structure</h3>
          <el-button @click="downFunction('mRNA')" class="download"
            >Download</el-button
          >
        </el-col>
      </el-col>
      <el-col :push="1" :span="22">
        <svg id="legend" width="140px" height="30px">
          <circle cx="15" cy="15" r="8" fill="#FF0000" stroke="rgb(35%,35%,35%)" stroke-width="1.2"></circle>
          <text x="15" y="15" style="text-anchor:middle;dominant-baseline: central;font-size:10px;font-family:Verdana">N</text>
          <text x="30" y="16" style="text-anchor:start;dominant-baseline: middle;font-size:16px;font-family:Arial">Predicted site</text>
        
        </svg>
        <div :id="`show-rna`" ref="rnashow" style="height: 802px">
          <div class="control-box">
            <el-button
              type="text"
              icon="el-icon-zoom-in"
              @click="zoomPic('in')"
            ></el-button>
            <el-button
              type="text"
              icon="el-icon-zoom-out"
              @click="zoomPic('out')"
            ></el-button>
            <el-button
              type="text"
              icon="el-icon-rank"
              @click="reset"
            ></el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-col :push="1">
          <h3 class="sub-title">Protein Structure</h3>
          <el-button @click="downFunction('Protein')" class="download"
            >Download</el-button
          >
        </el-col>
      </el-col>
      <el-col :span="22" :push="1">
        <div
          :id="`protein`"
          style="
            display: inline-block;
            width: 100%;
            height: 300px;
            border: solid 2px #15518d;
            border-radius: 15px;
          "
          ref="protein"
        ></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { FornaContainer } from "../../../public/js/fornac.js";
import IbsCharts from "../../../src/assets/js/IbsCharts";
import IbsUtils from "../../../src/assets/js/IbsUtils";
// import { RNASVGStyle } from "../../assets/js/RNASVGStyle";

export default {
  name: "rna-plot",
  props: {
    result: {},
    index: {},
    siteResult: {},
    // option: {
    //   type: Object,
    // },
  },
  data() {
    return {
      // state: true,
      // svg: "",
      // transform: [],
      loading: false,
      ratio: [], //不同序列二级结构的缩放比例
      zoomRange: [0.2, 5], //缩放范围
      originsize: [], //原始的viewbox属性
      downsize: [],
      shown: true, //是否显示序列文字
    };
  },
  methods: {
    createPic(id, width, height) {
      let container = new FornaContainer(`#${id}`, {
        applyForce: false,
        initialSize: [width, height],
        layout: "Naview",
      });
      let options = {
        structure: this.result.fold,
        sequence: this.result.seq,
      };
      let colortext = [];
      for (let i in this.result.sites) {
        colortext.push(`${this.result.sites[i].position}:red`);
      }
      container.addCustomColorsText(colortext.join(" "));
      container.addRNA(options.structure, options);
      return container;
    },
    // changedisplay() {
    //   this.$refs.rnashow.style.display = "block";
    // },
    reset() {
      this.result.svg.setAttribute("viewBox", this.originsize[this.index]);
      this.ratio[this.index] = 1;
    },
    downFunction(type) {
      let svgElement;
      let filename;
      if (type == "mRNA") {
        svgElement = this.$refs.rnashow.querySelector("#rna-plotting").cloneNode(true);
        svgElement.setAttribute("preserveAspectRatio", "");
        svgElement.setAttribute("viewBox", this.downsize[this.index]);
        filename = `${this.result.name}.svg`;
      } else {
        svgElement = this.$refs.protein.querySelector("svg");
        filename = `${this.result.name}_Protein.svg`;
      }
      let svgContent = svgElement.outerHTML;
      let href =
        "data:image/svg+xml;base64," +
        window.btoa(unescape(encodeURIComponent(svgContent)));
      let aLink = document.createElement("a");
      aLink.download = filename;
      aLink.href = href;
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    visualize(value, oldvalue) {
      let ibs = IbsCharts.init(this.$refs.protein, false);
      try {
        let option = IbsUtils.deepCopyObj(this.result.domain.option);
        let protein = ibs.createProteinOrNucleotide(option);
        let colorlist = this.calculateColor(this.result.domain.children.length);
        let nameslist = [];
        let initY = 20;
        let XcoordinateList = [];
        let TextList = [];
        for (let i = 0; i < this.result.domain.children.length; i++) {
          let childoption = IbsUtils.deepCopyObj(
            this.result.domain.children[i]
          );
          let domainname = childoption.name;
          nameslist.push(domainname);
          delete childoption.name;
          childoption.style = {
            color: colorlist[i],
          };
          protein.createDomain(childoption);
          // let markeroption = {
          //   coordinate:{
          //     cx:25,
          //     cy:initY + 25*i
          //   },
          //   shape: "roundRect",
          //   height: 15,
          //   width: 15,
          //   style: {
          //     color: colorlist[i],
          //     gradient:"centerToTopAndBottom"
          //   },
          //   editable: false,
          // };
          // let marker = ibs.createMarker(markeroption);
          // let textoption = {
          //   position: {
          //     x: marker.option.coordinate.cx + marker.option.width/2 + 5,
          //     y: marker.option.coordinate.cy,
          //   },
          //   style:{
          //     horizontalAlign:"right",
          //     verticalAlign:"central",
          //     // fontSize:"10px"
          //   },
          //   content:nameslist[i]
          // };
          let textoption = {
            position: {
              x: ibs.config.canvasWidth - 5,
              y: ibs.config.canvasHeight - 15 - 25*i,
            },
            style:{
              horizontalAlign:"end",
              verticalAlign:"central",
              // fontSize:"10px"
            },
            content:nameslist[i]
          };
          let text = ibs.createText(textoption);
          let textBox = text.nodes.getBBox();
          XcoordinateList.push(textBox.x);
          TextList.push(text);
        }
        for (let i = 0; i < this.result.domain.children.length; i++) {
          let x = Math.min(...XcoordinateList);
          let textoption = {
            position: {
              x: x + 15,
              y: ibs.config.canvasHeight - 15 - 20*i,
            },
            style:{
              horizontalAlign:"start",
              verticalAlign:"central",
              // fontSize:"10px"
            },
            content:nameslist[i],
            editable:false
          };
          let text = TextList[i].update(textoption);
          let markeroption = {
            coordinate:{
              cx:text.option.position.x - 7.5 - 5,
              cy:text.option.position.y
            },
            shape: "roundRect",
            height: 15,
            width: 15,
            style: {
              color: colorlist[i],
              gradient:"centerToTopAndBottom"
            },
            editable: false,
          };
          let marker = ibs.createMarker(markeroption);
        }
      } catch (err) {
        let tile = document.createElement("h2");
        tile.innerHTML = "Can't visualize this protein.";
        this.$refs.protein.replaceChild(tile, ibs.nodes);
        console.log(err);
      }
    },
    adjustSVG2(svg) {
      svg.setAttribute("id","rna-plotting");
      let line = svg.querySelectorAll("line");
      let circle = svg.querySelectorAll("circle");
      let text = svg.querySelectorAll("text");
      let lineNodes = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      let circleNodes = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      let textNodes = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      lineNodes.setAttribute("id", "lineNodes");
      circleNodes.setAttribute("id", "circleNodes");
      textNodes.setAttribute("id", "textNodes");
      for (let i = 0; i < line.length; i++) {
        lineNodes.appendChild(line[i]);
      }
      for (let i = 0; i < circle.length; i++) {
        circleNodes.appendChild(circle[i]);
      }
      for (let i = 0; i < text.length; i++) {
        textNodes.appendChild(text[i]);
      }
      svg.appendChild(lineNodes);
      svg.appendChild(circleNodes);
      svg.appendChild(textNodes);
      let namenode = textNodes.lastChild;
      textNodes.removeChild(namenode);
      this.$refs.rnashow.appendChild(svg);
      let { x, y, height, width } = circleNodes.getBBox();
      let size = Math.min(height, width);
      let downsize = Math.max(height, width);
      svg.setAttribute("viewBox", `${x} ${y} ${size} ${size}`);
      svg.setAttribute("preserveAspectRatio", "xMidYMin slice");
      this.originsize[this.index] = `0 0 ${size} ${size}`;
      this.downsize[this.index] = `0 0 ${downsize} ${downsize}`;
      // 拖拽事件
      svg.onmousedown = (e) => {
        e.preventDefault();
        let preViewBoxList = svg.getAttribute("viewBox").split(" ");
        let onDragStartX = e.clientX;
        let onDragStartY = e.clientY;
        document.onmousemove = (ev) => {
          let moveX = parseInt(preViewBoxList[0] - (ev.clientX - onDragStartX));
          let moveY = parseInt(preViewBoxList[1] - (ev.clientY - onDragStartY));
          let curViewBox = `${moveX} ${moveY} ${preViewBoxList[2]} ${preViewBoxList[3]}`;
          svg.setAttribute("viewBox", curViewBox);
        };
      };
      svg.onmouseup = (e) => {
        e.preventDefault();
        document.onmousemove = null;
      };
      this.mousewheel(svg);
    },
    calculateColor(step) {
      let start = [0, 0, 255]; //"#0000FF";
      let end = [255, 0, 0]; //"#00FFDD"
      // let mid = [0,255,222];//"#00FFDD"
      // let end = [255,0,0]//"#00FF00";
      let steplist = [0, 0, 0];
      let colorlist = [];
      for (let j = 0; j < 3; j++) {
        steplist[j] = Math.floor((end[j] - start[j]) / step);
      }
      for (let i = 0; i < step; i++) {
        let color = ["#"];
        for (let m = 0; m < 3; m++) {
          let rgb = (start[m] + steplist[m] * i).toString(16);
          if (rgb.length == 1) {
            rgb = "0" + rgb;
          }
          color.push(rgb);
        }
        colorlist.push(color.join(""));
      }
      return colorlist;
    },
    // 缩放函数，用了IBS的代码
    zoom(ratio) {
      let preRatio = this.ratio[this.index];
      this.ratio[this.index] = parseFloat(ratio);
      let changeRatio = parseFloat(ratio) / preRatio;
      let preViewBoxList = this.result.svg.getAttribute("viewBox").split(" ");
      let curWidth = parseInt(parseInt(preViewBoxList[2]) / changeRatio);
      let curHeight = parseInt(parseInt(preViewBoxList[3]) / changeRatio);
      let curX = Math.round(
        parseInt(preViewBoxList[0]) +
          (parseInt(preViewBoxList[2]) - curWidth) / 2
      );
      let curY = Math.round(
        parseInt(preViewBoxList[1]) +
          (parseInt(preViewBoxList[3]) - curHeight) / 2
      );
      let curViewBox = `${curX} ${curY} ${curWidth} ${curHeight}`;
      this.result.svg.setAttribute("viewBox", curViewBox);
    },
    zoomPic(direction) {
      var ratio;
      switch (direction) {
        case "in":
          ratio =
            this.ratio[this.index] + 0.1 > this.zoomRange[1]
              ? this.zoomRange[1]
              : this.ratio[this.index] + 0.1;
          this.zoom(ratio);
          break;
        case "out":
          ratio =
            this.ratio[this.index] - 0.1 < this.zoomRange[0]
              ? this.zoomRange[0]
              : this.ratio[this.index] - 0.1;
          this.zoom(ratio);
          break;
      }
    },
    // 绑定鼠标滚轮事件，用了IBS的代码
    mousewheel(svgdom) {
      if ("object" === typeof svgdom.onmousewheel) {
        // chrome
        svgdom.addEventListener("mousewheel", (e) => {
          e.preventDefault();
          let curRatio = this.ratio[this.index];
          let ratio = e.wheelDelta > 0 ? curRatio + 0.1 : curRatio - 0.1;
          ratio = ratio < this.zoomRange[0] ? this.zoomRange[0] : ratio;
          ratio = ratio > this.zoomRange[1] ? this.zoomRange[1] : ratio;
          this.zoom(ratio);
        });
      } else {
        // firefox: typeof element.onmousewheel === "undefine"
        svgdom.addEventListener("DOMMouseScroll", (e) => {
          e.preventDefault();
          let curRatio = this.ratio[this.index];
          let ratio = e.detail < 0 ? curRatio + 0.1 : curRatio - 0.1;
          ratio = ratio < this.zoomRange[0] ? this.zoomRange[0] : ratio;
          ratio = ratio > this.zoomRange[1] ? this.zoomRange[1] : ratio;
          this.zoom(ratio);
        });
      }
    },
    // 标记阳性位点
    signPositiveSite(svgdom) {
      let group = svgdom.querySelector("g");
      let seq = group.querySelector("#seq");
      let circlegroup = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      for (let i = 0; i < this.result.position.length; i++) {
        let textnode = seq.children[this.result.position[i]];
        let x = parseFloat(textnode.getAttribute("x"));
        let y = parseFloat(textnode.getAttribute("y"));
        let circle = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "circle"
        );
        circle.setAttribute("cx", x);
        circle.setAttribute("cy", y);
        circle.setAttribute("r", 8);
        circle.setAttribute("fill", "red");
        circlegroup.appendChild(circle);
      }
      group.insertBefore(circlegroup, seq);
    },
  },
  watch: {
    index: function (value, oldvalue) {
      // console.log("change result");
      this.ratio[value] = this.ratio[value] ? this.ratio[value] : 1;
      this.visualize(value, oldvalue);
      let svg = this.result.svg;
      let oldsvg = this.$refs.rnashow.querySelector("#rna-plotting");
      if (oldsvg) {
        this.$refs.rnashow.removeChild(oldsvg);
      }
      let flag = svg.querySelector("#circleNodes"); //是否曾经挂载过
      if (flag) {
        //挂载过（已经绑定过事件）
        this.$refs.rnashow.appendChild(svg);
      } else {
        this.adjustSVG2(svg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .show-box {
  margin-top: 40px !important;
  .el-dialog__body {
    height: 650px;
  }
}
#show-rna {
  z-index: 99;
  border: 1px solid #000;
}
.download {
  margin-bottom: 10px;
  // font-size: ;
  font-weight: 700;
  border: 1px solid;
  float: right;
}
.download:hover,
.download:focus {
  color: #15518d;
  border-color: #15518d;
}
#rna-visualize {
  margin: 25px 0 20px 0;
  // -moz-user-select: none; /* 火狐 */
  // -webkit-user-select: none; /* 谷歌 */
  // -ms-user-select: none; /* IE */
  // user-select: none;
  #visualize-button {
    color: rgb(21, 81, 141);
    border: 1px solid rgb(21, 81, 141);
  }
}
.sub-title {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 3px solid rgb(21, 81, 141);
  margin: 0px 0 15px 0;
}
#plotting-area {
  border: 1px solid #000;
  border-radius: 2px;
}

#legend {
  position:absolute;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/deep/ .control-box {
  position: absolute;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  left: 5px;
  top: 10px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  .el-button {
    padding: 5px 0px;
  }
  i {
    font-size: 20px;
  }
  .el-checkbox {
    margin-left: 10px;
  }
}
</style>
