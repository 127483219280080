<template>
  <footer id="footer">
    DeepOme &copy; {{ curYear }} The Ren Lab. All Rights Reserved
  </footer>
</template>

<script>
export default {
  data() {
    return {
      curYear: 2020,
    };
  },
  methods: {
    SetCurYear() {
      let date = new Date();
      this.curYear = date.getFullYear();
    },
  },
  mounted() {
    this.SetCurYear();
  },
};
</script>

<style lang="scss">
#footer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  font-size: 16px !important;
  height: 60px;
  background-color: rgb(21, 81, 141);
  line-height: 60px;
  color: #efeded;
  // margin-top: 30px;
  // position: absolute;
  // bottom: 0;
  width: 100%;
  z-index: 2000;
}
</style>
