<template>
  <div class="header">
    <div id="header-menu-background">
      <div id="logo"><span @click="$router.push('/home')">DeepOMe</span></div>
      <el-menu
        id="header-menu"
        router
        mode="horizontal"
        :default-active="activeIndex"
        background-color="#15518d"
        text-color="#ffffff"
        active-text-color="#ffffff"
      >
        <!-- <el-menu-item class="headerText" id="icon" index="/returnhome">DeepOMe</el-menu-item> -->
        <el-menu-item
          v-for="item in headerItems"
          :key="item.title"
          :index="item.route"
          class="headerText"
        >
          {{ item.title }}
          <!-- <router-link :to="item.route" class="headerText">{{item.title}}</router-link>  -->
        </el-menu-item>
        <!-- <a id="renlab" href="http://www.renlab.org/" target="_blank"></a> -->
        <el-menu-item class="headerText renlab" @click="testconsole">
          <a
            ref="renlab"
            id="renlab"
            href="http://www.renlab.org/"
            target="_blank"
            >RenLab</a
          >
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-header",
  data() {
    return {
      headerItems: [
        {
          title: "Home",
          route: "/home",
          index: "1",
        },
        {
          title: "Web Server",
          route: "/server",
          index: "2",
        },
        {
          title: "Help",
          route: "/help",
          index: "4",
        },
        {
          title: "Contact",
          route: "/contact",
          index: "5",
        },
        // {
        //   title: "Citation",
        //   route: "/citation",
        //   index: "6",
        // },
      ],
    };
  },
  methods: {
    // menuSelect(index, indexPath) {
    //   console.log(index);
    //   console.log(indexPath);
    // },
    testconsole() {
      // console.log(this.$refs.renlab)
      this.$refs.renlab.click();
    },
    backToHome() {
      this.$router.push("/home")
    }
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  border-bottom: 0px !important;
}
.headerText {
  // float: right;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  // border-top-right-radius: 10px;
  // border-top-left-radius: 10px;
}

#icon {
  width: 200px;
  font-weight: 700;
}
#logo {
  // position:absolute;
  text-align: left;
  text-indent: 8%;
  color: #fff;
  font-size: 50px;
  // line-height: 75px;
  background-color: #15518d;
  padding: 15px 0 30px;
}
#logo span:hover {
  cursor: pointer;
}
#header-menu-background {
  position: relative;
  width: 100%;
  min-height: 100px;
  // height: 100px;
  background-color: #15518d;
}
#header-menu {
  position: absolute;
  right: 12.5%;
  bottom: 0;
}
.is-active {
  background-color: rgb(17, 65, 113) !important;
}
.renlab {
  box-sizing: border-box;
  border: 0px;
  #renlab {
    vertical-align: baseline;
    text-decoration: none;
  }
}
</style>
