<template>
  <div id="score" v-loading.fullscreen="load">
    <el-row>
      <el-col :span="22" :push="1">
        <div class="score table-content">
          <el-card>
            <h2 class="title">Positive sites</h2>
            <el-button
              icon="el-icon-download"
              id="download"
              @mouseup.native="buttonBlur"
              @click="downloadFile"
              >Download</el-button
            >
            <el-table
              id="scoreTable"
              :data="sitesResult"
              default-expand-all
              header-cell-class-name="table-title"
              cell-class-name="table-cell"
              @row-click="tableClick"
              highlight-current-row
              :row-key="rowkey"
              :current-row-key="index"
              border
              ref="scoreTable"
              
            >
              <el-table-column
                prop="seqname"
                label="Sequence Name"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="position"
                label="Position"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column align="center">
                <template slot="header"> Sequence </template>
                <template slot-scope="seq">
                  <span
                    >{{ seq.row.sequence | frontSeq
                    }}<span style="color: #f00; font-weight: 700">{{
                      seq.row.sequence | site(seq.row.position)
                    }}</span
                    >{{ seq.row.sequence | backSeq }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Score"
                align="center"
                prop="score"
                sortable
              >
              </el-table-column>
              <el-table-column label="Cutoff" align="center">
                <template>
                  {{ job.fdr | cutoff }}
                </template>
              </el-table-column>
              <div slot="empty" id="empty">
                <p>No site was predicted.</p>
              </div>
            </el-table>
          </el-card>
        </div>

        <div class="visualize table-content">
          <el-card>
            <h2 class="title">Visualize</h2>
            <span id="current-title">Current sequence:</span>
            <el-select v-model="index">
              <el-option
                v-for="(item, num) in result"
                :key="num"
                :label="item.name"
                :value="num"
              ></el-option>
            </el-select>
            <visualize-section
              :index="index"
              :result="result[index]"
              :siteResult="sitesResult[index]"
              ref="visualize"
            ></visualize-section>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Visualize from "./Visualize";
import { thresoldTable } from "../../assets/js/ThresoldTable";
import axios from "axios";
// import { tree } from 'd3-hierarchy';
// import { SVG } from "@svgdotjs/svg.js";
// import "@svgdotjs/svg.draggable.js";

export default {
  name: "score-table",
  components: {
    "visualize-section": Visualize,
  },
  data() {
    return {
      ibs: null,
      result: [],
      sitesResult: [],
      cutoff: "",
      job: { uploadTime: "", fdr: "" },
      svgs: [],
      loading: false,
      loadingstate: [],
      index: "",
      dataloading: true,
      shown: 1,
      load:true,
      // rowkey:[]
    };
  },
  filters: {
    frontSeq(seq) {
      if (isNaN(parseInt(seq[0]))) {
        return seq.slice(0, 7);
      } else {
        return seq.slice(0, -8);
      }
    },
    backSeq(seq) {
      if (isNaN(parseInt(seq.slice(-2, -1)))) {
        return seq.slice(-7);
      } else {
        return seq.slice(8);
      }
    },
    site(seq, position) {
      if (isNaN(parseInt(seq[0]))) {
        return seq.slice(7, 8);
      } else {
        return seq.slice(position + 2, position + 3);
      }
    },
    cutoff(fdr) {
      let cutoff
      try {
        cutoff = thresoldTable[fdr].toFixed(3);
      } catch {

      }
      return cutoff;
    },
  },
  methods: {
    buttonBlur(e) {
      e.currentTarget.blur();
    },
    rowkey(row) {
      return row.index;
    },
    getFilename(date) {
      let year = date.getFullYear();
      let month =
        date.getMonth().toString().length == 1
          ? "0" + date.getMonth()
          : date.getMonth();
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      return `${year}-${month}-${day} ${hour}-${minute}-${second}`;
    },
    downloadFile() {
      let filename = this.job.uploadTime;
      let file = [
        `Submit time:${this.job.uploadTime}`,
        `FDR:${this.job.fdr}`,
        `Cutoff:${thresoldTable[this.job.fdr]}`,
        `SequenceName\tPosition\tSequence\tScore`,
      ];
      for (let i = 0; i < this.sitesResult.length; i++) {
        let line = [];
        for (const key in this.sitesResult[i]) {
          line.push(this.sitesResult[i][key]);
        }
        file.push(line.join("\t"));
      }
      let content = file.join("\n");
      let urlObj = window.URL || window.webkitURL || window;
      let url = urlObj.createObjectURL(new Blob([content]));
      let aLink = document.createElement("a");
      aLink.download = filename;
      aLink.href = url;
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    saveSVG(param) {
      let index = param.index;
      this.svgs[index] = param.svg;
    },
    async getResult(fileId) {
      let response = await axios
        .post("/api/result", { id: fileId })
        .catch((error) => {
          this.load = false;
          console.log("request result error.");
          console.log(error);
          this.$message.error({
            message:
              "<span style='font-size:20px;line-height:25px'>An error has occurred, please refresh the page and try again. If it happens frequently, please contact us.</span>",
            duration: 15000,
            showClose: true,
            dangerouslyUseHTMLString: true,
          });
        });
      //如果处理完成了，response.date.result是结果，否则是false
      if (response.data.result) {
        // this.load = false;
        console.log(response);
        let result = [];
        let sitesResult = [];
        let data = response.data.result;
        for (let i in data.name) {
          let seqObj = {
            name: data.name[i],
            svg: "",
            seq: "",
            domain: "",
            fdr: "",
            time: "",
            position:data.index[i]
          };
          for (let j in data.index[i]) {
            let start = Math.max(data.index[i][j] - 7, 0);
            let end = Math.min(data.index[i][j] + 8, data.seq[i].length);
            let seq = data.seq[i].slice(start, end);
            if (start === 0) {
              seq = "5'-" + seq;
            }
            if (end === data.seq[i].length) {
              seq = seq + "-3'";
            }
            let siteObj = {
              index: sitesResult.length,
              seqname: data.name[i],
              position: data.index[i][j] + 1,
              sequence: seq,
              // score: Math.round(parseFloat(data.score[i][j])*1000)/1000,
              score: parseFloat(data.score[i][j]).toFixed(3)
            };
            sitesResult.push(siteObj);
          }
          seqObj.svg = new DOMParser()
            .parseFromString(data.svg[i], "text/html")
            .querySelector("svg");
          seqObj.seq = data.seq[i];
          seqObj.domain = data.domain[data.name[i].split(" ")[0]];
          seqObj.fdr = data.fdr;
          result.push(seqObj);
          this.svgs.push(0);
          // this.rowkey.push(i);
        }
        this.job.uploadTime = data.time;
        this.job.fdr = data.fdr;
        this.result = result;
        this.sitesResult = sitesResult;
        this.dataloading = false;
        this.index = 0;
        // this.$refs.visualize.adjustSVG();
      }
      this.load = false;
    },
    tableClick(row, column, event) {
      // console.log(row);
      for (let i in this.result) {
        if (row.seqname == this.result[i].name) {
          this.index = parseFloat(i);
          break;
        }
      }
      // console.log(column);
    },
    async testSVG() {
      let response = await axios.post("/test");
      
      let svg = new DOMParser()
        .parseFromString(response.data, "text/html")
        .querySelector("svg");
      let rect = svg.querySelector("rect");
      let script = svg.querySelector("script");
      svg.removeChild(script);
      rect.setAttribute("width", 1512);
      rect.setAttribute("height", 800);
      // svg.removeChild(rect)
      svg.setAttribute("viewBox", "0 0 1512 800");
      svg.setAttribute("width", 1512);
      svg.setAttribute("height", 800);
      this.$refs.another.appendChild(svg);

      let group = svg.querySelector("g");
      let scale = Math.min(
        1512 / group.getBBox().width,
        800 / group.getBBox().height
      );
      let movex = 0 - group.getBBox().x;
      let movey = 0 - group.getBBox().y;
      group.setAttribute(
        "transform",
        `scale(${scale}),translate(${movex},${movey})`
      );
      this.$refs.another.onclick = () => {
        var seq = document.getElementById("seq");
        if (this.shown == 1) {
          seq.setAttribute("style", "visibility: hidden");
          this.shown = 0;
        } else {
          seq.setAttribute("style", "visibility: visible");
          this.shown = 1;
        }
      };
      svg.onmousedown = (e) => {
        e.preventDefault();
        let preViewBoxList = svg.getAttribute("viewBox").split(" ");
        let onDragStartX = e.clientX;
        let onDragStartY = e.clientY;
        svg.onmousemove = (ev) => {
          let moveX = parseInt(preViewBoxList[0]) - (ev.clientX - onDragStartX);
          let moveY = parseInt(preViewBoxList[1]) - (ev.clientY - onDragStartY);
          let curViewBox = `${moveX} ${moveY} ${preViewBoxList[2]} ${preViewBoxList[3]}`;
          svg.setAttribute("viewBox", curViewBox);
        };
      };
      svg.onmouseup = (e) => {
        e.preventDefault();
        svg.onmousemove = null;
      };
      svg.onmouseleave = (e) => {
        e.preventDefault();
        svg.onmousemove = null;
      };
    },
  },
  mounted() {
    this.getResult(this.$route.params.id);
    // this.$refs.scoreTable.setCurrentRow(this.sitesResult[0]);
  },
  watch: {
    sitesResult: function () {
      this.$nextTick(function () {
        this.$refs.scoreTable.setCurrentRow(this.sitesResult[0]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#score {
  text-align: left;
  padding: 20px 50px;
}
.seq-result {
  text-align: center;
  font-size: 18px;
}
/deep/ .result-table-header {
  color: #000;
}
#scoreTable {
  margin: 10px 0;
}
#download {
  margin-bottom: 10px;
  // font-size: ;
  font-weight: 700;
  border: 1px solid;
}
#download:hover,
#download:focus {
  color: #15518d;
  border-color: #15518d;
}
.visualize {
  margin-top: 15px;
  h4 {
    font-size: 22px;
    // text-indent: 2em;
    font-weight: 400;
    line-height: 20px;
    vertical-align: center;
  }
  i {
    display: inline-block;
    font-size: 20px;
    margin-right: 7px;
  }
  span {
    display: inline-block;
    vertical-align: center;
  }
  .canvas {
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border: solid 1px #15518d;
  }
}
.table-content {
  // width:10;
  margin: 0 auto 15px;
}
.title {
  z-index: 10;
  margin: -10px 0 10px -25px;
  padding-left: 1rem;
  padding-bottom: 5px;
  background-color: #15518d;
  color: #fff;
  height: 40px;
  line-height: 40px;
  // border-bottom: 1px solid rgb(21, 81, 141);
}
#no-positive {
  color: #e74e4e;
  letter-spacing: 2px;
}
.el-card {
  overflow: visible;
}
/deep/.el-table__expand-column .cell {
  display: none;
}
/deep/ .table-title {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
/deep/ .table-cell {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  color: #000;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #8badd3;
}
#current-title {
  font-size: 20px;
  font-weight: 700;
  margin-right: 20px;
}
#another {
  text-align: center;
  display: flex;
  display: -webkit-flex;
}
#empty {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  word-spacing:5px;
}
</style>
