import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Server from '../components/Server.vue'
import Help from '../components/Help.vue'
import Contact from '../components/Contact.vue'
import Citation from '../components/Citation.vue'
import ScoreSite from "../components/Server/ScoreSite.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/server',
    name: 'server',
    component: Server
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/citation',
    name: 'citation',
    component: Citation
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path:'/result/:id',
    component:ScoreSite,
  },
]

const router = new VueRouter({
  routes
})

export default router