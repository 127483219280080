<template>
  <div id="fdr-rate">
    <el-button @click="slidershow = !slidershow;buttonBlur($event)"
      >Advanced <i id="button-icon" :class="advanceIcon"></i> </el-button
    >
    <transition name="el-fade-in">
      <div v-show="slidershow" id="bar-zone">
        <el-row>
          <el-col>
            <h2>False Positive Rate (FDR)</h2>
          </el-col>
        </el-row>
        <el-row align="middle" type="flex">
          <el-col  :span="15">
            <el-slider
              v-model="fdrval"
              :min="0"
              :max="1"
              :step="0.01"
              show-input	
              :show-input-controls="false"
              id="slide-bar"
            ></el-slider>
          </el-col>
        </el-row>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "fdr-bar",
  props: {
    fdr: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      //   fdr: 0,
        // fdrval: 0.15,
      fdrstring: "",
      slidershow: false,
    };
  },
  computed: {
    advanceIcon() {
      if (!this.slidershow) {
        return "el-icon-caret-left";
      } else {
        return "el-icon-caret-right";
      }
    },
    fdrval:{
      get() {
        return this.fdr
      },
      set(value) {
        this.$emit("update:fdr",value)
      }
    }
  },
  methods: {
    // inputchange(val) {
    //   if (isNaN(val)) {
    //     return false;
    //   } else {
    //     if (val > 1) {
    //       return false;
    //     }
    //     this.fdrstring = String(val).substring(0, 4);
    //     let upload = parseFloat(this.fdrstring) || 0;
    //     this.$emit("update:fdr", upload);
    //   }
    // },
    // slidechange(val) {
    //   this.$emit("update:fdr", val);
    //   if (!val) {
    //     return false;
    //   }
    //   this.fdrstring = val + "";
    // },
    buttonBlur(e) {
      e.currentTarget.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
#fdr-rate {
  display: flex;
  display: -webkit-flex;
  margin-top: 30px;
  // justify-content: space-around;
  align-items: flex-start;
  h2 {
    font-size: 16px;
    line-height: 17px;
      // margin-top: 5px;
  }
}
#button-icon {
  font-size: 14px;
  vertical-align: middle;
}
#bar-zone {
  // width: 100%;
  margin-left: 20px;
}
/deep/ #slide-bar {
  width: 380px;
  .el-input__inner {//输入框
  width: 75px;
    // width: 100px;
  }
}
</style>