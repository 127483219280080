<template>
  <div id="contact">
    <div class="contactTitle">
      <div class="title">Contact Us</div>
    </div>
    <el-row>
      <el-col :span="18" :push="3">
        <div class="cardContainer">
          <el-card class="outerCard">
            <el-card class="innerCard">
              <h2 class="people">Jian Ren</h2>
              <p>
                <i class="el-icon-message"></i>
                <a href="mailto:renjian.sysu@gmail.com" class="email">
                  renjian@sysucc.org.cn</a
                >
              </p>
              <i class="job">Professor of Bioinformatics</i>
              <p>
                School of Life Sciences, Cancer Center, Sun Yat-sen University,
                Guangzhou 510060, China
              </p>
            </el-card>
            <el-card class="innerCard">
              <h2 class="people">Yubin Xie</h2>
              <p>
                <i class="el-icon-message"></i>
                <a href="mailto:xieyb6@mail.sysu.edu.cn" class="email">
                  xieyb6@mail.sysu.edu.cn</a
                >
              </p>
              <i class="job">Specially appointed associate researcher</i>
              <p>
                The First Affiliated Hospital, Sun Yat-sen University, Guangzhou
                510060, China
              </p>
            </el-card>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <v-footer></v-footer> -->
  </div>
</template>

<script>
// import Footer from './Footer';

export default {
  name: "contact",
  data() {
    return {};
  },
  // components: {
  //      "v-footer": Footer
  // },
};
</script>

<style lang="scss" scoped>
#contact {
  width: 100%;
}
.contactTitle {
  height: 100px;
  // background: linear-gradient(to bottom, #344872 0%, #69cbf1 100%);
  // width: 100%;
  // margin: 60px auto 20px;
  border: 0;
  color: #15518d;
  text-align: center;
  font-family: "Roboto", sans-serif, cursive;
  font-weight: 50px;
}
.title {
  text-align: left;
  position: relative;
  font-size: 50px;
  font-family: "Roboto", sans-serif, cursive;
  font-weight: bold;
  top: 50%;
  padding-left: 12.5%;
  transform: translateY(-50%);
}
.cardContainer {
  text-align: center;
}
.innerCard {
  margin: 20px auto;
  text-align: left;
  padding-left: 2%;
  border-left: #69cbf1 6px solid;
  font-size: 20px;
}
.people {
  color: #69cbf1;
  font-size: 30px;
}
.email {
  color: #69cbf1;
  text-decoration: none;
}
.job {
  font-weight: bold;
}
</style>