let thresoldTable = {
    0.00: 0.9989592,
    0.01: 0.9988845,
    0.02: 0.9982211,
    0.03: 0.9976568,
    0.04: 0.9932054,
    0.05: 0.97748077,
    0.06: 0.9633958,
    0.07: 0.9443218,
    0.08: 0.9172915,
    0.09: 0.86285865,
    0.10: 0.82178813,
    0.11: 0.8035693,
    0.12: 0.76656955,
    0.13: 0.7353224,
    0.14: 0.7059774,
    0.15: 0.68290186,
    0.16: 0.6390269,
    0.17: 0.60086393,
    0.18: 0.5635061,
    0.19: 0.539103,
    0.20: 0.51990026,
    0.21: 0.5041476,
    0.22: 0.4846024,
    0.23: 0.44663557,
    0.24: 0.41098908,
    0.25: 0.3708556,
    0.26: 0.3431473,
    0.27: 0.32525206,
    0.28: 0.30315906,
    0.29: 0.28563097,
    0.3: 0.26714006,
    0.31: 0.2400988,
    0.32: 0.22832333,
    0.33: 0.20642026,
    0.34: 0.19042629,
    0.35: 0.16654925,
    0.36: 0.15172525,
    0.37: 0.13977452,
    0.38: 0.12982215,
    0.39: 0.115615375,
    0.40: 0.10520661,
    0.41: 0.09814723,
    0.42: 0.08887495,
    0.43: 0.08115254,
    0.44: 0.07266485,
    0.45: 0.065937966,
    0.46: 0.061044224,
    0.47: 0.058075037,
    0.48: 0.053138543,
    0.49: 0.050108783,
    0.50: 0.04538738,
    0.51: 0.0415043,
    0.52: 0.037710454,
    0.53: 0.03423405,
    0.54: 0.031247636,
    0.55: 0.028352143,
    0.56: 0.025693724,
    0.57: 0.022366224,
    0.58: 0.020892428,
    0.59: 0.018736431,
    0.60: 0.016630441,
    0.61: 0.014980907,
    0.62: 0.013280616,
    0.63: 0.01197172,
    0.64: 0.010775399,
    0.65: 0.009834721,
    0.66: 0.008831212,
    0.67: 0.00782234,
    0.68: 0.0069973287,
    0.69: 0.0062881378,
    0.70: 0.0056236773,
    0.71: 0.004973503,
    0.72: 0.0044305148,
    0.73: 0.0039660437,
    0.74: 0.003503182,
    0.75: 0.003045422,
    0.76: 0.0026915846,
    0.77: 0.002344051,
    0.78: 0.0020385627,
    0.79: 0.0017425183,
    0.80: 0.0015016411,
    0.81: 0.0012598154,
    0.82: 0.0010634541,
    0.83: 0.0009067416,
    0.84: 0.0007640222,
    0.85: 0.0006456886,
    0.86: 0.0005470023,
    0.87: 0.00045247952,
    0.88: 0.00037505905,
    0.89: 0.00030171606,
    0.90: 0.00023785536,
    0.91: 0.00018496063,
    0.92: 0.00014020083,
    0.93: 0.000104043735,
    0.94: 7.4377924e-05,
    0.95: 4.977701e-05,
    0.96: 3.0444427e-05,
    0.97: 1.69148e-05,
    0.98: 7.6182955e-06,
    0.99: 2.3061846e-06,
    1.00: 5.8905396e-08
}

export {thresoldTable}